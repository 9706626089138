.accountBalance_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.accountBalance_container .accountBalance_box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0.4rem;
}

.accountBalance_container .accountBalance_Balance {
  width: 100%;
  height: 4.906667rem;
  background-color: transparent;
  background-image: url("https://changchong.oss-cn-hangzhou.aliyuncs.com/gzh/mine/balance.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.accountBalance_container .accountBalance_Balance .Balance_Num {
  font-size: 1.066667rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.accountBalance_container .accountBalance_Balance .Balance_text {
  font-size: 0.373333rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.accountBalance_container .accountBalance_btn {
  width: calc(100% - 1.066667rem);
  height: 1.226667rem;
  background-color: #3F88FB;
  border-radius: 0.613333rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  font-size: 0.48rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}