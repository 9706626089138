.favorite_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
}

.favorite_list {
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0.4rem;
}

.favorite_box {
  width: 100%;
  height: auto;
  background-color: #fff;
  margin-bottom: 0.4rem;
  box-shadow: 0 0 0.213333rem 0 rgba(63, 136, 251, 0.2);
  border-radius: 0.16rem;
  overflow: hidden;
}

.favorite_box .favorite_btn {
  width: 100%;
  height: 1.2rem;
  background-color: transparent;
  border-top: 0.026667rem solid #F7F7F7;
  font-size: 0.48rem;
  color: #3F88FB;
  font-weight: 500;
  text-align: center;
  line-height: 1.2rem;
}

.favorite_info {
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0.4rem;
}

.favorite_info .info_title {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.favorite_info .info_title .title {
  font-size: 0.426667rem;
  color: #333;
  font-weight: 500;
  text-align: center;
  padding-bottom: 0.16rem;
}

.favorite_info .info_title .info_fav {
  width: auto;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: flex-end;
  box-align: flex-end;
  -moz-box-align: flex-end;
  -webkit-box-align: flex-end;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  position: relative;
}

.favorite_info .info_title .title_fav {
  width: 0.666667rem;
  height: 0.666667rem;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-around;
  -moz-box-pack: space-around;
  -webkit--moz-box-pack: space-around;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.favorite_info .info_title .title_fav .fav_item {
  width: 0.133333rem;
  height: 0.133333rem;
  background-color: #9197A8;
  border-radius: 50%;
  overflow: hidden;
}

.favorite_info .info_title .fav_box {
  width: 3.306667rem;
  height: auto;
  background-color: transparent;
  position: absolute;
  top: 0.666667rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: flex-end;
  box-align: flex-end;
  -moz-box-align: flex-end;
  -webkit-box-align: flex-end;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.favorite_info .info_title .fav_box:hover .fav_info {
  background-color: #D7DAE1;
}

.favorite_info .info_title .fav_box:hover .fav_info::before {
  border-bottom-color: #D7DAE1;
}

.favorite_info .info_title .fav_box .fav_info::before {
  content: '';
  width: 0;
  height: 0;
  background-color: transparent;
  border-left: calc(0.16rem) solid transparent;
  border-right: calc(0.16rem) solid transparent;
  border-bottom: 0.16rem solid #fff;
  margin-right: 0.213333rem;
  position: absolute;
  top: -0.16rem;
  right: 0;
}

.favorite_info .info_title .fav_box .fav_info {
  width: 100%;
  height: 1.12rem;
  background-color: #fff;
  line-height: 1.12rem;
  font-size: 0.426667rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  border-radius: 0.16rem;
  box-shadow: 0 0 0.426667rem 0 rgba(42, 45, 51, 0.2);
}

.favorite_info .info_Address {
  font-size: 0.373333rem;
  color: #999;
  font-weight: 400;
  text-align: left;
  line-height: 0.533333rem;
}

.favorite_info .info_online {
  font-size: 0.373333rem;
  color: #3F88FB;
  font-weight: 400;
  text-align: left;
  line-height: 0.533333rem;
  padding-top: 0.16rem;
  text-decoration: underline;
}