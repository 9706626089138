@-webkit-keyframes move {
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

@keyframes move {
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

.loading_container {
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.loading_container .loading {
  width: 2.666667rem;
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  border-radius: 0.133333rem;
}

.loading_container .loading .loading_title {
  font-size: 0.426667rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  padding-bottom: 0.266667rem;
}

.loading_box {
  width: 2.133333rem;
  height: 2.133333rem;
  background-color: transparent;
  position: relative;
  -webkit-animation: move 3s linear infinite;
  animation: move 3s linear infinite;
}

.loading_box .load_item {
  width: 0.533333rem;
  height: 0.533333rem;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
}

.loading_box .load_item:nth-child(1) {
  background-color: #976ff7;
  top: 0.4rem;
  right: 0.533333rem;
}

.loading_box .load_item:nth-child(2) {
  background-color: #5262f7;
  right: 0.4rem;
  top: 1.066667rem;
}

.loading_box .load_item:nth-child(3) {
  background-color: #f5c24c;
  bottom: 0.4rem;
  left: 0.533333rem;
}

.loading_box .load_item:nth-child(4) {
  background-color: #ea6a3a;
  left: 0.4rem;
  top: 0.533333rem;
}