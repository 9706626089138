.myCoupon_container {
  width: 100vw;
  height: auto;
  background-color: #fff;
  min-height: 100vh;
  padding: 0.4rem;
}

.myCoupon_container .myCoupon_box {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.myCoupon_container .CouponList_box {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.myCoupon_container .CouponList_box .list_head {
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0.266667rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.myCoupon_container .CouponList_box .list_all {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  margin-top: 0.426667rem;
}

.myCoupon_container .CouponList_box .list_all .all_btn {
  font-size: 0.373333rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}

.myCoupon_container .CouponList_box .head_title {
  font-size: 0.426667rem;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.myCoupon_container .CouponList_box .head_recommend {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.myCoupon_container .CouponList_box .head_recommend .recommend_text {
  font-size: 0.373333rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
}

.myCoupon_container .CouponList_box .head_recommend .recommend_icon {
  width: 0.586667rem;
  height: 0.586667rem;
  background-color: transparent;
}

.myCoupon_container .myCoupon_list {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.myCoupon_container .myCoupon_list .list_items {
  width: 100%;
  height: auto;
  background-color: transparent;
  margin-bottom: 0.266667rem;
}

.myCoupon_container .myCoupon_list .list_item {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  border: 0.026667rem solid transparent;
  border-radius: 0.213333rem;
  overflow: hidden;
}

.myCoupon_container .myCoupon_list .list_item.noData {
  font-size: 0.533333rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.myCoupon_container .myCoupon_list .list_item.noData .item_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  width: 100%;
  height: 2.666667rem;
  background-color: transparent;
}

.myCoupon_container .myCoupon_list .list_item .item_box {
  background-size: cover;
}

.myCoupon_container .myCoupon_list .list_item .item_cardNum {
  font-size: 0.32rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  line-height: 0.533333rem;
}

.myCoupon_container .myCoupon_list .list_item.lineCard {
  border-color: #A067FF;
}

.myCoupon_container .myCoupon_list .list_item.lineCard .item_box {
  background-image: url("https://changchong.oss-cn-hangzhou.aliyuncs.com/gzh/card/linecard.png");
}

.myCoupon_container .myCoupon_list .list_item.pointCard {
  border-color: #FB3838;
}

.myCoupon_container .myCoupon_list .list_item.pointCard .item_box {
  background-image: url("https://changchong.oss-cn-hangzhou.aliyuncs.com/gzh/card/pointCard.png");
}

.myCoupon_container .myCoupon_list .list_item.freeCard {
  border-color: #F79E33;
}

.myCoupon_container .myCoupon_list .list_item.freeCard .item_box {
  background-image: url("https://changchong.oss-cn-hangzhou.aliyuncs.com/gzh/card/freeCard.png");
}

.myCoupon_container .item_box {
  width: 100%;
  height: 2.666667rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0.133333rem 0.4rem;
}

.myCoupon_container .item_box .item_top {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.myCoupon_container .item_box .item_top .top_btn {
  width: 1.706667rem;
  height: 0.746667rem;
  background-color: #fff;
  font-size: 0.373333rem;
  color: #FF131F;
  font-weight: 400;
  text-align: center;
  border-radius: 0.453333rem;
  overflow: hidden;
}

.myCoupon_container .item_box .item_center {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.myCoupon_container .item_box .item_center.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.myCoupon_container .item_box .item_center.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.myCoupon_container .item_box .item_bottom {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.myCoupon_container .item_box .font28 {
  font-size: 0.746667rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 0 0.133333rem;
}

.myCoupon_container .item_box .font20 {
  font-size: 0.533333rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 0 0.133333rem;
}

.myCoupon_container .item_box .font14 {
  font-size: 0.373333rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.myCoupon_container .item_box .font12 {
  font-size: 0.32rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.myCoupon_container .item_box .font12_line {
  font-size: 0.32rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}

.myCoupon_container .item_box .font10 {
  font-size: 0.266667rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}