.chargeDetails {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.chargeDetails .chargeDetailsImg {
  width: 100%;
  height: auto;
}