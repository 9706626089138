.tradingInfo_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  padding: 0.4rem;
}

.tradingInfo_container .tradingInfo_box {
  padding: 0.4rem;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 0.4rem;
}

.tradingInfo_container .Info_money {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.tradingInfo_container .Info_money .money_Num {
  font-size: 0.8rem;
  color: #333;
  font-weight: 500;
  text-align: center;
  line-height: 1.12rem;
}

.tradingInfo_container .Info_money .money_explain {
  font-size: 0.373333rem;
  color: #999;
  font-weight: 400;
  text-align: center;
  line-height: 0.48rem;
  display: -webkit-flex;
  display: flex;
}

.tradingInfo_container .Info_money .money_explain .red {
  color: #E53424;
}

.tradingInfo_container .Info_money .money_icon {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding-bottom: 0.4rem;
  margin-top: -0.4rem;
  border-bottom: 0.026667rem dashed #F7F7F7;
}

.tradingInfo_container .Info_money .money_icon .icon_left {
  width: 0.4rem;
  height: 0.8rem;
  background-color: #F9F9F9;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  margin-left: -0.4rem;
}

.tradingInfo_container .Info_money .money_icon .icon_right {
  width: 0.4rem;
  height: 0.8rem;
  background-color: #F9F9F9;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  margin-right: -0.4rem;
}

.tradingInfo_container .info_list {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.tradingInfo_container .info_list .list_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  font-size: 0.426667rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  line-height: 1.066667rem;
}

.tradingInfo_container .info_list .list_item .item_word {
  font-size: 0.426667rem;
  color: #333;
  font-weight: 400;
  text-align: right;
  width: 6.4rem;
  height: auto;
  background-color: transparent;
  line-height: 0.533333rem;
  word-wrap: break-word;
}

.tradingInfo_container .info_list .list_item_cross {
  margin: 0.4rem 0;
  width: 100%;
  height: 0.026667rem;
  background-color: transparent;
  background: #F7F7F7;
}

.tradingInfo_container .btn {
  width: 80%;
  height: 1.226667rem;
  background-color: transparent;
  text-align: center;
  line-height: 1.226667rem;
  color: #fff;
  font-size: 0.48rem;
  background: #3F88FB;
  position: absolute;
  bottom: 1.066667rem;
  left: 10%;
}