.orderDetails {
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0 0.533333rem;
  padding-bottom: 1.066667rem;
  box-sizing: border-box;
  background: #ffffff;
}

.orderDetails .orderStatus {
  width: 100%;
  height: 0.746667rem;
  background-color: transparent;
  font-size: 0.533333rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
  text-align: left;
  margin: 0.533333rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.orderDetails .orderStatus .pickup-btn {
  width: 1.92rem;
  height: 0.826667rem;
  background-color: transparent;
  font-size: 0.346667rem;
  color: #313132;
  text-align: center;
  line-height: 0.8rem;
  border: 0.026667rem solid #9EA0A0;
  border-radius: 0.426667rem;
  box-sizing: border-box;
}

.orderDetails .order_message {
  width: 100%;
  height: auto;
  background-color: transparent;
  border-radius: 0.213333rem;
  padding: 0 0.4rem;
  padding-bottom: 0.026667rem;
  box-shadow: 0 0 0.213333rem 0 rgba(63, 136, 251, 0.2);
  box-sizing: border-box;
}

.orderDetails .order_message .order_head {
  width: 100%;
  height: auto;
  background-color: transparent;
  padding: 0.4rem 0;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  line-height: 0.64rem;
}

.orderDetails .order_message .order_head .order_sum {
  color: #9197A8;
}

.orderDetails .order_message .order_item {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 0.666667rem;
}

.orderDetails .order_message .order_item .imgage_text {
  -webkit-flex: 1 1;
  flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.orderDetails .order_message .order_item .imgage_text .order_img {
  width: 1.546667rem;
  height: 1.546667rem;
  background-color: transparent;
  border-radius: 0.16rem;
  background: #aaaaaa;
}

.orderDetails .order_message .order_item .imgage_text .shop_details {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding-left: 0.266667rem;
  box-sizing: border-box;
}

.orderDetails .order_message .order_item .imgage_text .shop_details .shop_details_name {
  width: 80%;
  height: 0.96rem;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: left;
}

.orderDetails .order_message .order_item .imgage_text .shop_details .money {
  text-align: left;
}

.orderDetails .order_message .order_item .order_num {
  width: 0.8rem;
  height: 100%;
  background-color: transparent;
  line-height: 1.546667rem;
}

.orderDetails .order_message .border {
  width: calc(100%-0.8rem);
  height: 0.026667rem;
  background-color: transparent;
  background: #EAEAEA;
  box-sizing: border-box;
  margin-bottom: 0.213333rem;
}

.orderDetails .order_message .money_sum {
  box-sizing: border-box;
  line-height: 0.933333rem;
}

.orderDetails .order_message .order_money {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.4rem;
}

.orderDetails .order_message .order_money .money_sums {
  font-weight: 700;
}

.orderDetails .personal_details {
  width: 100%;
  height: auto;
  background-color: transparent;
  margin-top: 0.266667rem;
  box-shadow: 0 0 0.213333rem 0 rgba(63, 136, 251, 0.2);
  padding: 0 0.4rem;
  padding-bottom: 0.4rem;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 0.213333rem;
}

.orderDetails .personal_details .title {
  text-align: left;
  line-height: 1.466667rem;
}

.orderDetails .personal_details .personal_details_item {
  margin-top: 0.266667rem;
}

.orderDetails .personal_details .personal_details_item .personal_name {
  min-width: 2.133333rem;
  text-align: left;
}

.orderDetails .personal_details .personal_details_item .personal_status {
  -webkit-flex: 1 1;
  flex: 1 1;
  text-align: right;
}

.phone {
  margin-top: 0.266667rem;
  line-height: 1.226667rem;
  width: 100%;
  height: auto;
  background-color: transparent;
  box-shadow: 0 0 0.213333rem 0 rgba(63, 136, 251, 0.2);
  border-radius: 0.213333rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
}

.phone .tel {
  width: 0.586667rem;
  height: 0.586667rem;
  background-color: transparent;
  position: relative;
  top: 0.32rem;
  left: 0.213333rem;
}

.order_btn {
  padding: 0 0.533333rem;
  box-sizing: border-box;
  margin-top: 0.533333rem;
}

.btn {
  width: 100%;
  height: 1.226667rem;
  background-color: transparent;
  border-radius: 0.613333rem;
  background: #3F88FB;
  color: #ffffff;
  text-align: center;
  line-height: 1.226667rem;
  font-size: 0.48rem;
}

.font15 {
  font-size: 0.4rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
}

.font159 {
  font-size: 0.4rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
}

.font139 {
  font-size: 0.346667rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
}

.font15_w {
  font-size: 0.4rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
}

.font17_w {
  font-size: 0.453333rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
}

.font13 {
  font-size: 0.346667rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
}

.space {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.not {
  font-size: 0.4rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
}