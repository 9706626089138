@charset "UTF-8";

* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-style: normal;
  /*防止点击闪烁*/
  -webkit-tap-highlight-color: transparent;
  /*缩放网页，文字大小不变*/
  -webkit-text-size-adjust: none;
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-weight: normal;
  /*阻止复制剪切*/
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:hover {
  outline: none;
}

/* 设置iconfont标签font-family */

[class^="icon-"],
[class*=" icon-"] {
  font-family: "iconfont";
  font-size: 0.426667rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: #F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.026667rem rgba(0, 0, 0, 0);
  border-radius: 0.266667rem;
  background-color: #F5F5F5;
}

/*去除虚线*/

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input {
  border: none;
}

/*背景色*/

html,
body {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #f5f5f5;
}

input[type="button"],
input[type="submit"],
input[type="search"],
input[type="reset"] {
  -webkit-appearance: none;
}

a {
  text-decoration: none;
  /*ios禁止页面在新窗口打开*/
  -webkit-touch-callout: none;
}

button,
textarea,
input {
  background-color: transparent;
  border: none;
  outline: none;
}