.container {
  position: relative;
}

.container .center_box {
  margin-top: 1.6rem;
}

.container .center_box .center_img {
  width: 50vw;
  height: auto;
  background-color: transparent;
}

.container .center_box .center_describe {
  font-size: 0.346667rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
}