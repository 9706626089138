.goods-order-pay {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: initial;
  -moz-box-pack: initial;
  -webkit--moz-box-pack: initial;
  -webkit-align-items: initial;
  box-align: initial;
  -moz-box-align: initial;
  -webkit-box-align: initial;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab,
.goods-order-pay .goods-top .goods-info .goods-list .goods-item {
  display: flex;
  flex-direction: row;
  justify-content: initial;
  align-items: initial;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: initial;
  -moz-box-pack: initial;
  -webkit--moz-box-pack: initial;
  -webkit-align-items: initial;
  box-align: initial;
  -moz-box-align: initial;
  -webkit-box-align: initial;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.goods-order-pay .goods-top .distribution-info .consignee-info,
.goods-order-pay .goods-top .distribution-info .consignee-info .consignee-info-title,
.goods-order-pay .goods-top .goods-info .goods-list .goods-list-header,
.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count,
.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count .count-action,
.goods-order-pay .goods-bottom,
.goods-order-pay .goods-bottom .pay-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.goods-order-pay .goods-top .distribution-info .oneself-box .oneself-info-title,
.goods-order-pay .goods-top .goods-info .order-info .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.goods-order-pay .goods-top .distribution-info,
.goods-order-pay .goods-top .goods-info {
  background-color: #FFFFFF;
  box-shadow: 0 0 0.213333rem 0 rgba(63, 136, 251, 0.2);
  border-radius: 0.213333rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-list-header .shop-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow-right {
  width: 0.586667rem;
  height: 0.586667rem;
  background-color: transparent;
  margin-right: -0.133333rem;
}

.goods-order-pay {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  position: relative;
}

.goods-order-pay .goods-top {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 0.266667rem 0.4rem;
  overflow-y: auto;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab {
  height: 1.6rem;
  border-top-left-radius: 0.213333rem;
  border-top-right-radius: 0.213333rem;
  overflow: hidden;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab .tab-item {
  -webkit-flex: 1 1;
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  color: #9197A8;
  font-size: 0.453333rem;
  font-weight: 400;
  line-height: 0.64rem;
  background-color: #F4F8FF;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab .tab-item .tab-item-img {
  width: 0.586667rem;
  height: 0.586667rem;
  background-color: transparent;
  margin-right: 0.133333rem;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab .tab-item.active {
  background-color: #FFFFFF;
  color: #2A2D33;
  font-weight: 500;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab .adshop {
  -webkit-flex: 1 1;
  flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.goods-order-pay .goods-top .distribution-info .distribution-tab .adshop .tab-item-img {
  width: 0.586667rem;
  height: 0.586667rem;
  background-color: transparent;
  position: relative;
  left: 0.533333rem;
}

.goods-order-pay .goods-top .distribution-info .oneself-box {
  padding: 0.266667rem 0.4rem 0.533333rem;
}

.goods-order-pay .goods-top .distribution-info .oneself-box .oneself-info-title {
  padding: 0.533333rem 0 0.266667rem;
  color: #2A2D33;
  font-size: 0.453333rem;
  font-weight: 500;
  line-height: 0.666667rem;
}

.goods-order-pay .goods-top .distribution-info .oneself-box .oneself-info-title .contact-merchant {
  color: #3F88FB;
  font-size: 0.4rem;
  font-weight: 400;
  line-height: 0.586667rem;
}

.goods-order-pay .goods-top .distribution-info .delivery-box {
  padding: 0.266667rem 0.4rem;
}

.goods-order-pay .goods-top .distribution-info .address-detail {
  color: #9197A8;
  font-size: 0.4rem;
  line-height: 0.56rem;
}

.goods-order-pay .goods-top .distribution-info .address-detail.delivery-address {
  margin-top: 0.48rem;
  margin-bottom: 0.266667rem;
}

.goods-order-pay .goods-top .distribution-info .consignee-info {
  height: 1.173333rem;
  color: #9197A8;
  font-size: 0.4rem;
}

.goods-order-pay .goods-top .distribution-info .consignee-info.border {
  border-bottom: solid 0.026667rem #EAEAEA;
}

.goods-order-pay .goods-top .distribution-info .consignee-info .consignee-info-title {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.goods-order-pay .goods-top .distribution-info .consignee-info .consignee-info-title .consignee-info-text {
  color: #2A2D33;
  font-size: 0.4rem;
  line-height: 0.56rem;
}

.goods-order-pay .goods-top .distribution-info .consignee-info .consignee-info-title .consignee-info-text.name {
  font-weight: 500;
  margin-right: 1.013333rem;
}

.goods-order-pay .goods-top .distribution-info .consignee-info .consignee-info-title .address-icon {
  width: 0.453333rem;
  height: 0.533333rem;
  background-color: transparent;
  margin-right: 0.133333rem;
}

.goods-order-pay .goods-top .distribution-info .consignee-info .consignee-info-title .shipping-address-title {
  color: #2A2D33;
  font-size: 0.453333rem;
  font-weight: 500;
  line-height: 0.666667rem;
}

.goods-order-pay .goods-top .goods-info {
  margin-top: 0.266667rem;
  padding: 0.133333rem 0.4rem 0;
}

.goods-order-pay .goods-top .goods-info .goods-list {
  border-bottom: solid 0.026667rem #EAEAEA;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-list-header {
  height: 1.066667rem;
  color: #9197A8;
  font-size: 0.346667rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-list-header .shop-name {
  -webkit-flex: 1 1;
  flex: 1 1;
  width: 0;
  margin-right: 0.533333rem;
  color: #2A2D33;
  font-size: 0.4rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item {
  padding: 0.133333rem 0 0.4rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-img {
  width: 1.546667rem;
  height: 1.546667rem;
  background-color: transparent;
  border-radius: 0.16rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text {
  -webkit-flex: 1 1;
  flex: 1 1;
  width: 0;
  margin-left: 0.266667rem;
  margin-right: 0.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text .goods-name {
  color: #2A2D33;
  font-size: 0.346667rem;
  line-height: 0.48rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text .goods-sort {
  position: relative;
  top: 0.133333rem;
  -webkit-flex: 1 1;
  flex: 1 1;
  background-color: #FAFAFA;
  border-radius: 0.106667rem;
  padding: 0 0.133333rem;
  box-sizing: border-box;
  overflow: hidden;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text .goods-sort .sort-box {
  display: inline-block;
  margin-right: 0.133333rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text .goods-sort .sort-img {
  width: 0.506667rem;
  height: 0.506667rem;
  position: absolute;
  right: 0;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text .goods-sort .sort-img img {
  width: 0.32rem;
  height: auto;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-text .goods-price {
  margin-top: 0.106667rem;
  color: #2A2D33;
  font-size: 0.4rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count {
  height: 1.546667rem;
  color: #2A2D33;
  font-size: 0.4rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count .count-action {
  height: 1.066667rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count .count-action .action-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  width: 0.426667rem;
  height: 0.426667rem;
  background-color: transparent;
  border-radius: 0.213333rem;
  font-size: 0.453333rem;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count .count-action .action-content.reduce {
  margin-right: 0.16rem;
  border: solid 0.026667rem #3F88FB;
  color: #3F88FB;
}

.goods-order-pay .goods-top .goods-info .goods-list .goods-item .goods-count .count-action .action-content.add {
  margin-left: 0.16rem;
  background-color: #3F88FB;
  color: #FFFFFF;
  padding-bottom: 0.053333rem;
}

.goods-order-pay .goods-top .goods-info .order-info {
  padding: 0.213333rem 0 0.32rem;
}

.goods-order-pay .goods-top .goods-info .order-info .item {
  height: 0.96rem;
  color: #2A2D33;
  font-size: 0.4rem;
}

.goods-order-pay .goods-top .goods-info .order-info .item .item-value {
  color: #9197A8;
  font-weight: 500;
}

.goods-order-pay .goods-top .goods-info .order-info .item .item-value.price {
  color: #2A2D33;
}

.goods-order-pay .goods-top .goods-info .order-info .item .order-remarks {
  -webkit-flex: 1 1;
  flex: 1 1;
  margin-left: 0.533333rem;
  line-height: 0.853333rem;
  text-align: right;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  color: #2A2D33;
  font-size: 0.4rem;
}

.goods-order-pay .goods-bottom {
  background-color: #FFFFFF;
  box-shadow: 0 -0.266667rem 0.16rem 0 rgba(63, 136, 251, 0.04);
  height: 1.626667rem;
  padding: 0 0.426667rem;
}

.goods-order-pay .goods-bottom .pay-info {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.goods-order-pay .goods-bottom .pay-info .pay-money-label {
  padding-top: 0.16rem;
  color: #454e5f;
  font-size: 0.426667rem;
  line-height: 0.586667rem;
}

.goods-order-pay .goods-bottom .pay-info .pay-money {
  color: #E53424;
  font-size: 0.746667rem;
  line-height: 1.066667rem;
}

.goods-order-pay .goods-bottom .pay-button {
  width: 2.453333rem;
  height: 1.013333rem;
  background-color: transparent;
  border-radius: 0.506667rem;
  background-color: #3F88FB;
  color: #FFFFFF;
  font-size: 0.48rem;
}

.goods-order-pay .goods-bottom .pay-button.disabled {
  background-color: #D7DAE1;
}

.goods-order-pay .popup {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.2);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.goods-order-pay .popup .popup-banner {
  -webkit-flex: 1 1;
  flex: 1 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.goods-order-pay .popup .popup-box {
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  max-height: calc(100vh - 2.346667rem);
  overflow-y: scroll;
  margin-bottom: 2.08rem;
}

.goods-order-pay .popup .popup-head {
  width: 100%;
  height: 2.133333rem;
  padding: 0.266667rem;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.goods-order-pay .popup .popup-head .popup-goods-img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.16rem;
}

.goods-order-pay .popup .popup-head .popup-price {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding-left: 0.4rem;
  box-sizing: border-box;
  color: #EC3331;
  font-size: 0.373333rem;
  font-weight: 700;
}

.goods-order-pay .popup .popup-head .popup-price .popup-price-title {
  font-size: 0.213333rem;
  font-weight: 400;
}

.goods-order-pay .popup .popup-head .close {
  width: 0.4rem;
  height: 0.4rem;
}

.goods-order-pay .popup .popup-info .popup-module {
  padding: 0 0.266667rem;
  box-sizing: border-box;
}

.goods-order-pay .popup .popup-info .popup-module .module-title {
  color: #313133;
  font-size: 0.373333rem;
}

.goods-order-pay .popup .popup-info .popup-module .module-type {
  padding: 0.08rem 0.266667rem;
  box-sizing: border-box;
  background: #F5F5F5;
  border-radius: 0.106667rem;
  color: #313133;
  font-size: 0.32rem;
  height: 0.693333rem;
  display: inline-block;
  margin-top: 0.32rem;
  margin-right: 0.32rem;
}

.goods-order-pay .popup .popup-info .popup-module .type-red {
  color: #EC3331;
}

.goods-order-pay .popup .popup-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.08rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0  0.48rem;
  box-sizing: border-box;
  background: #fff;
}

.goods-order-pay .popup .popup-bottom .popup-btn {
  width: 100%;
  height: 1.013333rem;
  border-radius: 0.506667rem;
  background: #4281FF;
  color: #fff;
  font-size: 0.4rem;
  text-align: center;
  line-height: 1.013333rem;
}