.balanceCharge_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.balanceCharge_container .balanceCharge_box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0.4rem;
}

.balanceCharge_container .balanceCharge_box .balanceCharge_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: flex-start;
  box-align: flex-start;
  -moz-box-align: flex-start;
  -webkit-box-align: flex-start;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.balanceCharge_container .Balance_title {
  font-size: 0.426667rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  padding: 0.266667rem 0;
}

.balanceCharge_container .balanceCharge_Balance {
  width: 100%;
  height: auto;
  background-color: transparent;
  border: 0.026667rem solid #F7F7F7;
  border-radius: 0.266667rem;
  padding-top: 0.4rem;
}

.balanceCharge_container .balanceCharge_btn {
  width: calc(100% - 1.066667rem);
  height: 1.226667rem;
  background-color: #3F88FB;
  border-radius: 0.613333rem;
  font-size: 0.48rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.balanceCharge_container .balanceCharge_btn.active {
  background-color: rgba(63, 136, 251, 0.55);
}