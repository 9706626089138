.money_list {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: flex-start;
  box-align: flex-start;
  -moz-box-align: flex-start;
  -webkit-box-align: flex-start;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding-right: 0.4rem;
}

.money_list .list_item {
  width: calc(100%/3 - 0.4rem);
  height: 1.28rem;
  background-color: transparent;
  line-height: 1.28rem;
  border: 0.026667rem solid #3F88FB;
  font-size: 0.426667rem;
  color: #3F88FB;
  font-weight: 400;
  text-align: center;
  margin: 0 0 0.266667rem 0.4rem;
  border-radius: 0.266667rem;
}

.money_list .list_item.active {
  background-color: #3F88FB;
  color: #fff;
}