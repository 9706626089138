.list_box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background: #F9F9F9 !important;
  overflow-y: auto;
  padding: 0 !important;
}

.list_box .list_head {
  width: 100%;
  height: 1.226667rem;
  background-color: transparent;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  font-size: 0.426667rem;
  color: #2A2D33;
  margin-bottom: 0.4rem;
  position: relative;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 0.533333rem;
}

.list_box .list_head .head_name {
  margin-top: 0.266667rem;
  margin-right: 0.266667rem;
  box-sizing: border-box;
}

.list_box .list_head .head_names {
  font-weight: 600;
  position: relative;
}

.list_box .head_names::before {
  bottom: 0;
  content: '';
  width: 0.746667rem;
  height: 0.053333rem;
  background: #3F88FB;
  border-radius: 0.053333rem;
  position: absolute;
  bottom: -0.053333rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.list_box .listLine {
  padding: 0 0.4rem;
}