@-webkit-keyframes tipMove {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }

  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes tipMove {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
  }

  50% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.modal_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9900;
  background-color: rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.container_box {
  width: 7.2rem;
  height: auto;
  background-color: #fff;
  padding: 0.32rem 0;
  border-radius: 0.266667rem;
  overflow: hidden;
  margin-bottom: 1.333333rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.container_box .modal_title {
  font-size: 0.48rem;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.container_box .modal_content {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: flex-end;
  -moz-box-pack: flex-end;
  -webkit--moz-box-pack: flex-end;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0 0.533333rem 0.48rem;
  min-height: 2.133333rem;
}

.container_box .modal_content.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.container_box .modal_content .center_input {
  width: 100%;
  height: 1.013333rem;
  background-color: transparent;
  border-bottom: 0.026667rem solid #F7F7F7;
  font-size: 0.426667rem;
  color: #666;
  font-weight: 400;
  text-align: center;
}

.container_box .modal_content .list_item {
  font-size: 0.426667rem;
  color: #666;
  font-weight: 400;
  text-align: center;
  width: 6.133333rem;
  height: auto;
  background-color: transparent;
  line-height: 0.586667rem;
}

.container_box .modal_content .list_item .item_phone {
  font-size: 0.426667rem;
  color: #3F88FB;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}

.container_box .modal_btn {
  width: 100%;
  height: 1.013333rem;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0 0.533333rem;
}

.container_box .modal_btn .btn_item {
  width: auto;
  height: 1.013333rem;
  background-color: #F0F0F0;
  font-size: 0.426667rem;
  color: #999;
  font-weight: 400;
  text-align: center;
  border-radius: 0.506667rem;
  -webkit-flex: 1 1;
  flex: 1 1;
}

.container_box .modal_btn .btn_item.cancel {
  margin-right: 0.533333rem;
}

.container_box .modal_btn .btn_item.confirm {
  width: auto;
  height: 1.013333rem;
  background-color: #3F88FB;
  font-size: 0.426667rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
}