.h5go {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.h5go .head {
  line-height: 0.8rem;
  font-size: 0.533333rem;
  text-align: center;
  margin-top: 0.266667rem;
}

.h5go .img {
  width: 60%;
  margin: 5% 20% 0;
}

.h5go .text {
  text-align: center;
  color: #999;
  font-size: 0.373333rem;
}

.h5go .course {
  margin-top: 0.266667rem;
  text-align: center;
  font-size: 0.426667rem;
}

.h5go .btn {
  width: 80%;
  height: 1.066667rem;
  text-align: center;
  line-height: 1.066667rem;
  position: fixed;
  bottom: 1.333333rem;
  left: 10%;
}