.low_carbon_detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.low_carbon_detail .low_carbon_detail_img {
  width: 100%;
  height: auto;
}