.updateversion_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.updateversion_container .updateversion_box {
  width: 100%;
  height: auto;
  background-color: transparent;
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.updateversion_container .updateversion_box img {
  width: 1.493333rem;
  height: 1.493333rem;
  background-color: transparent;
}

.updateversion_container .updateversion_box h3 {
  font-size: 0.426667rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 600;
}

.updateversion_container .updateversion_box p {
  font-size: 0.373333rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
  margin-top: 0.4rem;
}