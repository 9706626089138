.arrow-right {
  width: 0.586667rem;
  height: 0.586667rem;
  background-color: transparent;
  margin-right: -0.133333rem;
}

.goods-consignee-info {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: initial;
  -moz-box-pack: initial;
  -webkit--moz-box-pack: initial;
  -webkit-align-items: initial;
  box-align: initial;
  -moz-box-align: initial;
  -webkit-box-align: initial;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  background-color: #FFFFFF;
}

.consignee-info-top {
  -webkit-flex: 1 1;
  flex: 1 1;
  padding: 0.213333rem 0.4rem 0;
  overflow-y: auto;
}

.consignee-info-top .input-item {
  padding-top: 0.133333rem;
  border-bottom: #EAEAEA solid 0.026667rem;
}

.consignee-info-top .input-item .address {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.consignee-info-top .input-item .address.input {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.consignee-info-top .input-item .input {
  width: 100%;
  height: 1.093333rem;
  background-color: transparent;
  color: #2A2D33;
  font-size: 0.4rem;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.consignee-info-top .gender-item {
  padding: 0.133333rem 0 0.08rem;
  border-bottom: #EAEAEA solid 0.026667rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.consignee-info-top .gender-item .gender-radio-group {
  width: 4.053333rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.consignee-info-top .gender-item .gender-radio-group .gender-radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  color: #2A2D33;
  font-size: 0.4rem;
}

.consignee-info-top .gender-item .gender-radio-group .gender-radio .radio {
  display: inline-block;
  vertical-align: middle;
  width: 0.533333rem;
  height: 0.533333rem;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  line-height: 0.533333rem;
  color: #d8d8d8;
}

.consignee-info-top .gender-item .gender-radio-group .gender-radio .radio::after {
  content: "";
  display: block;
  width: 0.533333rem;
  height: 0.533333rem;
  border-radius: 50%;
  text-align: center;
  line-height: 0.453333rem;
  font-size: 0.373333rem;
  color: #fff;
  border: 0.026667rem solid #ddd;
  background-color: #fff;
  box-sizing: border-box;
}

.consignee-info-top .gender-item .gender-radio-group .gender-radio .radio:checked::after {
  content: "L";
  transform: matrix(-0.76604, -0.64279, -0.64279, 0.66044, -0.5, 0);
  -webkit-transform: matrix(-0.76604, -0.64279, -0.64279, 0.76604, -0.5, 0);
  border-color: #3F88FB;
  background-color: #3F88FB;
}

.consignee-info-top .gender-item .gender-radio-group .gender-radio .radio-label {
  padding-left: 0.266667rem;
  padding-right: 0.08rem;
}

.consignee-info-top .item-title {
  color: #2A2D33;
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 1.173333rem;
}

.consignee-info-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  height: 2.293333rem;
}

.consignee-info-bottom .button-confirm {
  width: 8.133333rem;
  height: 1.226667rem;
  background-color: transparent;
  background-color: #3F88FB;
  border-radius: 0.613333rem;
  color: #FFFFFF;
  font-size: 0.48rem;
}