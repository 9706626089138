.order {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  padding: 0 0.4rem;
  box-sizing: border-box;
  background: #F9F9F9;
  overflow-y: auto;
}

.order .img_box {
  color: #9197A8;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 0.346667rem;
}

.order .img_box .img {
  width: 5.333333rem;
  height: 3.733333rem;
  background-color: transparent;
  margin-top: -5.333333rem;
}

.order .loader {
  width: 100%;
  height: 1.333333rem;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.order .order_item {
  width: 100%;
  height: auto;
  background-color: transparent;
  background: #fff;
  margin-top: 0.266667rem;
  box-shadow: 0 0 0.213333rem 0 rgba(63, 136, 251, 0.2);
  padding: 0.4rem 0.4rem;
  box-sizing: border-box;
}

.order .order_head {
  width: 100%;
  height: 0.56rem;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.order .order_head .order_name {
  font-size: 0.4rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
}

.order .order_head .order_state {
  font-size: 0.4rem;
  color: #333;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
}

.order .order_head .orange {
  color: #E53424;
}

.order .order_head .blue {
  color: #3F88FB;
}

.order .order_head .gray {
  color: #9197A8;
}

.order .order_border {
  width: 100%;
  height: 0.053333rem;
  background-color: transparent;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: #EAEAEA;
}

.order .order_details {
  width: 100%;
  height: 1.386667rem;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.order .order_details .order_details_shop {
  width: calc(100%-1.066667rem);
  height: 100%;
  background-color: transparent;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  border-right: 0.426667rem solid #ffffff;
}

.order .order_details .order_details_shop ::-webkit-scrollbar {
  height: 0;
}

.order .order_details .order_details_shop .shop_img {
  width: 1.386667rem;
  height: 1.386667rem;
  background-color: transparent;
  display: inline-block;
  margin-left: 0.266667rem;
  background: #F0F0F0;
  border-radius: 0.16rem;
}

.order .order_details .order_details_shop :first-child {
  margin: 0;
}

.order .order_details .order_tetalis_num {
  width: 1.066667rem;
  height: 100%;
  background-color: transparent;
  background: #ffffff;
  font-size: 0.346667rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
  padding: 0 0.346667rem;
  box-sizing: border-box;
  position: relative;
  left: 0.133333rem;
}

.order .time {
  width: 100%;
  height: 0.48rem;
  background-color: transparent;
  margin-top: 0.4rem;
  font-size: 0.346667rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
  text-align: left;
}

.order .money {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: 0.266667rem;
}

.order .money .money_name {
  font-size: 0.346667rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  position: relative;
  top: 0.08rem;
}

.order .money .money_num {
  font-size: 0.453333rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 700;
}

.order .pickup {
  width: 100%;
  height: 0.933333rem;
  background-color: transparent;
  margin-top: 0.133333rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.order .pickup .pickup-btn {
  width: 1.92rem;
  height: 0.826667rem;
  background-color: transparent;
  font-size: 0.346667rem;
  color: #313132;
  text-align: center;
  line-height: 0.8rem;
  border: 0.026667rem solid #9EA0A0;
  border-radius: 0.426667rem;
  box-sizing: border-box;
}

.order .unpaid {
  margin-top: 0.266667rem;
  display: -webkit-flex;
  display: flex;
}

.order .unpaid .unpaid_L {
  -webkit-flex: 1 1;
  flex: 1 1;
}

.order .unpaid .btn {
  width: 1.866667rem;
  height: 0.8rem;
  background-color: transparent;
  border-radius: 0.4rem;
  text-align: center;
  line-height: 0.8rem;
  font-size: 0.346667rem;
}

.order .unpaid .patfor {
  background: #3F88FB;
  color: #ffffff;
  margin-left: 0.4rem;
}