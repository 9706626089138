.active_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.active_container .active_top {
  background-color: #E1471F;
  width: 100%;
  height: 8.053333rem;
  background-color: red;
  background-image: url(../../assets/img/top.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.active_container .active_center {
  width: 100%;
  height: auto;
  background-image: url(../../assets/img/center.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #FAC656;
  font-size: 0;
}

.active_container .active_center .list_top {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 0.373333rem;
}

.active_container .active_center .list_top .line {
  font-size: 0.586667rem;
  color: #3C181A;
  position: relative;
  bottom: -0.266667rem;
  padding-left: 0.133333rem;
  padding-right: 0.133333rem;
}

.active_container .active_center .list_top .title_item {
  text-align: center;
}

.active_container .active_center .list_top .title_item .fonts {
  position: relative;
  z-index: 999;
  bottom: -0.373333rem;
  font-size: 0.453333rem;
  color: #3C181A;
  font-weight: bold;
}

.active_container .active_center .list_top .title_item .xialine {
  display: block;
  border-bottom: solid 0.186667rem #FFFFFF;
  z-index: 888;
}

.active_container .active_center .list_top .title_item.active {
  padding: 0 0.08rem;
}

.active_container .active_center .list_top .title_item.active .fonts {
  font-size: 0.586667rem;
}

.active_container .active_center .list {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  padding: 0.533333rem 0.8rem;
  box-sizing: border-box;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.active_container .active_center .list .list_item {
  width: 3.893333rem;
  height: 4.8rem;
  background-image: url(../../assets/img/moneybg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  margin-bottom: 0.586667rem;
  position: relative;
}

.active_container .active_center .list .list_item .list_title {
  box-sizing: border-box;
  padding-left: 0.133333rem;
  padding-right: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.active_container .active_center .list .list_item .list_content {
  padding-bottom: 0.266667rem;
}

.active_container .active_center .list .list_item .list_bot .font-18 {
  font-size: 0.48rem;
  color: #F74C28;
  font-weight: bold;
}

.active_container .active_bottom {
  background-color: #D54033;
  width: 100%;
  height: 21.066667rem;
  background-image: url(../../assets/img/bottom1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.active_container .font-44 {
  font-size: 1.173333rem;
  color: #F74C28;
}

.active_container .font-18 {
  font-size: 0.48rem;
  color: #F74C28;
}

.active_container .font-14 {
  font-size: 0.373333rem;
  color: #6D510A;
}