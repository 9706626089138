.list_box {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0.4rem;
  overflow-y: auto;
}

.itemRecord {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-left: #3F88FB solid 0.24rem;
  border-radius: 0.16rem;
  margin-bottom: 0.266667rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 0.4rem 0.4rem 0.4rem 0.266667rem;
}

.itemRecord:last-child {
  margin-bottom: 0;
}

.itemRecord .Record_info {
  width: calc(100% - 2.133333rem);
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: flex-start;
  box-align: flex-start;
  -moz-box-align: flex-start;
  -webkit-box-align: flex-start;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.itemRecord .Record_info .info_title {
  font-size: 0.426667rem;
  color: #333;
  font-weight: 500;
  text-align: center;
  line-height: 0.586667rem;
  padding-bottom: 0.266667rem;
}

.itemRecord .Record_info .info_time {
  font-size: 0.373333rem;
  color: #999;
  font-weight: 400;
  text-align: center;
  line-height: 0.533333rem;
}

.itemRecord .Record_money {
  width: 2.133333rem;
  height: auto;
  background-color: transparent;
  font-size: 0.64rem;
  color: #333;
  font-weight: 500;
  text-align: center;
  line-height: 0.906667rem;
}

.itemRecord .Record_money.active {
  font-size: 0.64rem;
  color: #3F88FB;
  font-weight: 500;
  text-align: center;
}