.powerGraph {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-color: #F5F6F7;
}

.powerGraph .powerGraph_head {
  width: 100%;
  background: red;
  display: -webkit-flex;
  display: flex;
}

.powerGraph #mian {
  margin: 0.4rem;
  height: 7.36rem;
  background: #FFFFFF;
  border-radius: 0.213333rem;
}

.powerGraph .power_curve {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: #999;
  font-size: 0.533333rem;
}

.powerGraph .power_curve .power_img {
  width: 50%;
  height: auto;
  background-color: transparent;
}