.newyear_active {
  width: 100%;
  height: auto;
  background-color: transparent;
}

.newyear_active .active_box {
  width: 100%;
  height: auto;
  background-color: transparent;
  background-image: url(../../assets/img/bg1.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 6.48rem;
  padding-bottom: 0.533333rem;
}

.newyear_active .active_box .active_cont {
  padding-left: 0.666667rem;
  padding-right: 0.666667rem;
  margin-top: 2.666667rem;
}

.newyear_active .active_box .active_cont .active_date {
  width: 100%;
  height: auto;
  background-color: transparent;
  font-size: 0.373333rem;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.266667rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.newyear_active .active_box .active_cont .money_list {
  width: 100%;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  margin: 0;
  padding: 0;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item {
  border: none;
  width: 100%;
  height: 2.266667rem;
  background-color: transparent;
  background-color: #FFFFFF;
  margin-bottom: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_box {
  width: 2.24rem;
  height: 2.266667rem;
  background-color: transparent;
  background-image: url(../../assets/img/left_bg88.png);
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 2.266667rem;
  font-weight: 600;
  position: relative;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_box .tags {
  font-size: 0.48rem;
  color: #E02020;
  font-weight: 400;
  text-align: center;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_box .money {
  font-size: 0.853333rem;
  color: #E02020;
  font-weight: 400;
  text-align: center;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_box .type {
  font-size: 0.213333rem;
  color: #189858;
  font-weight: 400;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 0.666667rem;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_box91 {
  background-image: url(../../assets/img/left_bg91.png);
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_box9 {
  background-image: url(../../assets/img/left_bg9.png);
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .left_bg88 {
  background-image: url(../../assets/img/left_bg88.png);
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box {
  width: 6.426667rem;
  height: 2.266667rem;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: space-between;
  -webkit--moz-box-pack: space-between;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  padding-left: 0.266667rem;
  padding-right: 0.133333rem;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box .left_cont .top_cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: flex-start;
  -moz-box-pack: flex-start;
  -webkit--moz-box-pack: flex-start;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box .left_cont .top_cont .title {
  font-size: 0.4rem;
  color: #2A2D33;
  font-weight: 400;
  text-align: center;
  font-weight: 600;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box .left_cont .top_cont .sign {
  width: 0.72rem;
  height: 0.346667rem;
  background-color: transparent;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box .left_cont .top_cont .sign img {
  width: 1.066667rem;
  height: 0.346667rem;
  background-color: transparent;
  margin-left: 0.133333rem;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box .left_cont .bot_cont {
  width: 100%;
  height: auto;
  background-color: transparent;
  font-size: 0.293333rem;
  color: #9197A8;
  font-weight: 400;
  text-align: center;
  text-align: left;
}

.newyear_active .active_box .active_cont .money_list .moneylist_item .right_box .right_cont button {
  width: 1.813333rem;
  height: 0.746667rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  background: -webkit-linear-gradient(bottom, #198354, #18b05d);
  background: linear-gradient(360deg, #198354, #18b05d);
  border-radius: 0.373333rem;
  font-size: 0.426667rem;
  color: #FFFFFF;
  font-weight: 400;
  text-align: center;
  font-weight: 600;
}

.newyear_active .active_box .active_bot {
  width: 100%;
  height: 15.466667rem;
  background-color: transparent;
  background-image: url(../../assets/img/bot_bg1.png);
  background-size: 100%;
  background-repeat: no-repeat;
}