.yzad_container {
  width: 100%;
  height: 100vh;
  background-color: transparent;
}

.yzad_container .yzad_box {
  width: 100%;
  height: 10%;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  box-sizing: border-box;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 0.533333rem;
}

.yzad_container .yzad_box .btn {
  width: 45%;
  height: 70%;
  background-color: transparent;
  text-align: center;
  line-height: 100%;
  color: #fff;
  background: #3F88FB;
}

.yzad_container .img_ad {
  width: 100%;
  height: 90vh;
  background-color: transparent;
}