#dark_toast {
  position: fixed;
  top: 40%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 40%;
  background: #333;
  z-index: 1000;
  padding: 0.133333rem 0.266667rem;
  background: rgba(29, 29, 29, 0.73);
  border-radius: 0.133333rem;
  color: #fff;
  text-align: center;
  min-width: 2.666667rem;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

#dark_toast .dark_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

#dark_toast .iconfont {
  width: 1.066667rem;
  height: 1.066667rem;
  background-color: transparent;
}

#dark_toast .iconfont.fail {
  width: 0.533333rem;
  height: 0.533333rem;
  background-color: transparent;
}

#dark_toast .iconfont.loading {
  -webkit-animation: rotate-loop 1.5s linear infinite;
  animation: rotate-loop 1.5s linear infinite;
}

@-webkit-keyframes rotate-loop {
  from {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes rotate-loop {
  from {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}