.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
  margin-top: 1.333333rem;
}

.container .center_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: column;
  -o-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.container .center_box .center_img {
  width: 3.466667rem;
  height: 3.466667rem;
  background-color: transparent;
  padding-bottom: 0.266667rem;
}

.container .center_box .center_describe {
  font-size: 0.373333rem;
  color: #333;
  font-weight: 400;
  text-align: center;
}

.container .center_box .center_btn {
  padding-top: 1.333333rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: "";
  display: -webkit-flex;
  /* Safari */
  display: -ms-flexbox;
  -webkit-flex-direction: row;
  -o-flex-direction: row;
  -moz-flex-direction: row;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -webkit--moz-box-pack: center;
  -webkit-align-items: center;
  box-align: center;
  -moz-box-align: center;
  -webkit-box-align: center;
  -webkit-flex-wrap: "";
  -moz-flex-wrap: "";
  -ms-align-content: center;
  -o-align-content: center;
  -webkit-align-content: center;
  align-content: center;
}

.container .center_box .center_btn .btn_item {
  width: 3.2rem;
  height: 0.96rem;
  background-color: transparent;
  font-size: 0.426667rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
  line-height: 0.906667rem;
  border: 0.026667rem solid #3F88FB;
  border-radius: 0.96rem;
  background-color: #3F88FB;
}

.container .center_box .center_btn .btn_item:first-child {
  font-size: 0.426667rem;
  color: #3F88FB;
  font-weight: 400;
  text-align: center;
  background-color: #fff;
  margin-right: 1.333333rem;
}